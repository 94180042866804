import { useCallback } from 'react';

import { clearStorages, getStorageItem, setStorageItem, StorageType } from '@sbiz/util-browser';

import { useSiteDataClearanceStorageItem } from '../../../hooks/useSiteDataClearanceStorageItem';
import { STORAGE_KEYS } from '../../constants';
import { API_RESOURCES } from '../resources';
import { useClearCache } from './useClearCache';

type StorageEntry = [string, string];
type StorageEntries = Map<StorageType, StorageEntry[]>;

const STORAGE_RETENTION = new Map<StorageType, string[]>([
  ['local', []],
  ['session', [STORAGE_KEYS.apiUrl, STORAGE_KEYS.redirectAfterLogin]],
]);

export function useClearAppData() {
  const { setValue: setSiteDataClearance } = useSiteDataClearanceStorageItem();
  const clearCache = useClearCache();

  return useCallback(() => {
    const savedSiteData = saveSiteData();
    clearStorages();
    restoreSiteData(savedSiteData);

    setSiteDataClearance(new Date().toISOString());

    clearCache(undefined, { isRevalidationDisabled: true, omit: [API_RESOURCES.featureFlag.path] });
    clearCache('featureFlag');
  }, [clearCache, setSiteDataClearance]);
}

function restoreSiteData(savedData: StorageEntries) {
  for (const [type, entries] of savedData.entries()) {
    for (const [key, value] of entries) {
      setStorageItem(key, value, type);
    }
  }
}

function saveSiteData() {
  const savedData: StorageEntries = new Map([
    ['local', []],
    ['session', []],
  ]);

  for (const [type, keys] of STORAGE_RETENTION.entries()) {
    const savedEntries = savedData.get(type) as StorageEntry[];

    for (const key of keys) {
      const value = getStorageItem<string>(key, type);
      if (value) {
        savedEntries.push([key, value]);
      }
    }
  }

  return savedData;
}
